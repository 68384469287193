import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { ReorderList, ReorderListItem } from '@shared/components/reorder-list/reorder-list.interfaces';
import { FoldersDataService, ObjectItem, ObjectItemAttributes, ObjectsService, ViewerService } from '@services';
import {
  FolderDataType,
  FolderPickerSelection,
  IFolder,
  IFolderAttributes
} from '@modules/management/modules/folders/model/folders.interfaces';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { each, filter, includes, map, reject } from 'lodash';

type ContentListItemAttrs = IFolderAttributes & ObjectItemAttributes;

export interface ContentListItem extends ReorderListItem, IFolder, ObjectItem {
  iconName?: string;
  iconClass?: string;
  contentCategoryName?: string;
  type: FolderDataType;
  attributes?: ContentListItemAttrs;
}

export interface ContentPickerSelection {
  contentFolderID?: number;
  contentID?: number;
}

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
})
export class ContentListComponent extends CustomFormComponent implements OnInit, OnDestroy {
  @ViewChild('contentTypeRef', { static: true }) contentTypeRef: TemplateRef<HTMLElement>;
  @ViewChild('contentNameRef', { static: true }) contentNameRef: TemplateRef<HTMLElement>;
  @ViewChild('contentRemoveRef', { static: true }) contentRemoveRef: TemplateRef<HTMLElement>;
  @Input() draggable: boolean = true;

  public pickerContentItems: FolderPickerSelection[] = [];
  public folderType = FolderDataType.CONTENT;
  public onParentIdsChange: Observable<ContentPickerSelection[]>;
  public prompt: string;
  public parentIds: ContentPickerSelection[] = [];
  public contentItems: ContentListItem[] = [];
  public parentContentItems: ContentListItem[] = [];
  public panelConfig = {
    count: 0,
    title: '',
    removeTitle: this.translateService.instant('MGMT_DETAILS.Remove_Content'),
    subTitle: this.translateService.instant('MGMT_DETAILS.Content_Items')
  };
  public orderByObject: string[] = ['mediaType', 'description'];
  public currentOrderByObject: { [key: string]: string[] } = {
    parentOrder: this.orderByObject,
    order: this.orderByObject
  };
  public reorderConfig: ReorderList<ContentListItem> = {
    draggable: this.draggable,
    enableCheckbox: false,
    items: [],
    columns: []
  };

  private subscriptionDestroy: Subject<number[]> = new Subject<number[]>();

  constructor(
    private objectsService: ObjectsService,
    private translateService: TranslateService,
    private router: Router,
    private viewerService: ViewerService,
    private folderService: FoldersDataService
  ) {
    super();
  }

  ngOnInit() {
    this.defineItems();
    this.initParentIdsSubscriber();
    this.initReorderConfig();
  }

  ngOnDestroy() {
    this.subscriptionDestroy.next([]);
    this.subscriptionDestroy.complete();
  }

  public doReorder(): void {
    this.setContentItems(this.contentItems);
  }

  public toggleSelection(): void {
    const contentItems: number[] = map(filter(this.contentItems, 'selected'), 'objectID');
    this.setRemovalCount(contentItems.length);
  }

  public onRemove() {
    this.setRemovalCount(0);
    this.setContentItems(reject(this.contentItems, 'selected'));
    this.initPickerContentItems();
  }

  public removeBy(targetItem: ContentListItem): void {
    this.setContentItems(reject(this.contentItems, (item) => {
      return item.objectID ? item.objectID === targetItem.objectID : item.folderID === targetItem.folderID;
    }));
  }

  public onSelectedContent(items: FolderPickerSelection[]) {
    const contentObjects: ContentListItem[] = [];

    each(items, (item) => {
      if (item.itemID) {
        contentObjects.push(this.objectsService.getCachedObjectById(item.itemID) as ContentListItem);
      } else if (item.folderID) {
        contentObjects.push(this.folderService.getFolderByID(item.folderID) as ContentListItem);
      }
    });

    this.prepareListItems(contentObjects);
    this.setContentItems(contentObjects);
  }

  public openDetailPage(content: ContentListItem) {
    if (content.objectID) {
      const object = this.objectsService.getCachedObjectById(content.objectID, 'content', true);
      const objectType = this.objectsService.getObjectType(object.mediaType);

      if (includes(['image', 'video', 'audio'], objectType)) {
        this.router.navigate(['pages/management/content/quick-view', { id: object.objectID }]);
      } else {
        this.viewerService.showByObjectId(object.objectID);
      }
    }
  }

  private defineItems(): void {
    this.initContentItems();
    this.initParentContentItems(this.parentIds);
    this.checkDuplicate();

    if (this.reorderConfig.draggable) {
      this.currentOrderByObject.order = [];
    }

    this.reorderConfig.items = this.contentItems;
  }

  private prepareListItems(items: ContentListItem[]): void {
    each(items, (item) => {
      if (item.objectID) {
        item.iconName = this.objectsService.getIconByType(item.mediaType);
      } else if (item.folderID) {
        item.iconClass = 'folder-icon';
      }
    });
  }

  private setRemovalCount(count: number): void {
    this.panelConfig.count = count;
    this.panelConfig.title = `${this.panelConfig.count} ${this.translateService.instant('SHARED.Selected')}`;
  }

  private setContentItems(items: ContentListItem[]) {
    this.contentItems = items;
    this.checkDuplicate();
    this.initPickerContentItems();
    this.formValue = map(this.contentItems, (item) => {
      return item?.objectID ? { contentID: item.objectID } : { contentFolderID: item.folderID };
    });
    this.reorderConfig.items = this.contentItems;
  }

  private initParentIdsSubscriber() {
    if (this.onParentIdsChange) {
      this.onParentIdsChange.pipe(
        takeUntil(this.subscriptionDestroy)
      ).subscribe((ids: ContentPickerSelection[]) => {
        this.initContentItems();
        this.initParentContentItems(ids);
        this.checkDuplicate();
      });
    }
  }

  private checkDuplicate() {
    const parentObjectIDs = filter(map(this.parentContentItems, 'objectID'));
    const parentFolderIDs = filter(map(this.parentContentItems, 'folderID'));

    this.contentItems = reject(this.contentItems, (item) => {
      return item?.objectID ? includes(parentObjectIDs, item.objectID) : includes(parentFolderIDs, item.folderID);
    });
  }

  private initContentItems() {
    this.contentItems = this.buildContentItemsByFolderSelection(this.formValue);
    this.prepareListItems(this.contentItems);
    this.initPickerContentItems();
  }

  private initPickerContentItems() {
    this.pickerContentItems = filter(map(this.contentItems, (contentItem) => {
      return contentItem.objectID ? { itemID: contentItem.objectID } : { folderID: contentItem.folderID };
    }), (item) => {
      return !!(item.itemID ?? item.folderID);
    });
  }

  private initParentContentItems(ids: ContentPickerSelection[]) {
    this.parentContentItems = this.buildContentItemsByFolderSelection(ids);
    this.prepareListItems(this.parentContentItems);
  }

  private buildContentItemsByFolderSelection(ids: ContentPickerSelection[]): ContentListItem[] {
    const contentItems: ContentListItem[] = [];

    each(ids, (item) => {
      if (item?.contentID) {
        contentItems.push(this.objectsService.getCachedObjectById(item?.contentID, 'content', true) as ContentListItem);
      } else if (item?.contentFolderID) {
        contentItems.push(this.folderService.getFolderByID(item.contentFolderID) as ContentListItem);
      }
    });

    return filter(contentItems);
  }

  private initReorderConfig() {
    this.reorderConfig.draggable = this.draggable;

    this.reorderConfig.columns = [
      {
        title: 'SHARED.Type',
        class: 'type-field',
        template: this.contentTypeRef
      },
      {
        title: 'SHARED.NAME',
        class: 'name-field',
        template: this.contentNameRef
      }
    ];

    if (!this.draggable) {
      this.reorderConfig.columns.push({
        title: '',
        class: 'remove-button',
        template: this.contentRemoveRef
      });
    }
  }
}
