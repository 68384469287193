import { IObjectStringKeyMap } from '@shared/models';
import { Asset } from '@services/assets/asset.interfaces';
import { IProperty } from '@services/property/property-model.interfaces';
import { DataTableOptions, FormConfig, FormField, ObjectItem } from '@services';
import { ContentPickerSelection } from '@modules/management/pages/details/content/components';
import { FilterData, FilterMenuFormConfig } from '@shared/modules/menu-filter/services/filter-menu.service';
import { SelectedProperty } from '@modules/management/modules/property-picker/model/property-picker.interfaces';

export interface IFolderInput {
  config: IFolderConfig;
  panelConfig: IPanelConfig;
  data?: IDataItems[];
  tableConfig?: DataTableOptions;
  tabTitle?: string;
  moveAction?: IFolderMoveActionConfig;
  filter?: FolderListFilter;
  accessCheck?: (folder: IFolder) => boolean;
  icon?: (folder: IFolder) => string;
}

export interface FolderListFilter {
  config: FilterMenuFormConfig;
  filterData?: FilterData;
  onChange: (filterResult: FilterData) => void;
}

export interface IDataItems {
  folderID: number;
}

export interface IFolderMoveActionConfig {
  panelTitle: string;
  pageConfig: IFolderMoveActionPageConfig;
}

interface IFolderMoveActionFormConfig extends Omit<FormConfig, 'fields' | 'prefix'> {
  fields?: FormField[];
  prefix?: string;
}

export interface IFolderMoveActionPageConfig {
  title?: string;
  formConfig: {
    folderFieldTitle: string;
    config?: IFolderMoveActionFormConfig;
  },
  moveRequestHandler: (formData: any) => Promise<any>;
}

export interface IFolderConfig {
  type: FolderDataType;
  link: string;
  allowCopy?: boolean;
  addFolderBtnText: string;
  addItemBtnText: string;
  addItemLink: string;
  title: string;
  noItemsAtRoot?: boolean;
  noAddFolder?: boolean;
}

export interface IPanelConfig {
  title?: string;
  subTitle?: string;
  buttons?: IPanelButton[];
  selectedIds?: number[];
}

export interface IPanelButton {
  buttonText?: string;
  action: () => Promise<void>;
}

export interface IFolder {
  timestamp: number;
  status: string;
  statusText: string;
  folderID: number;
  parentID: number;
  type: FolderDataType;
  title: string;
  parentAttributes?: IFolderAttributes;
  selectedProperties?: SelectedProperty[];
  attributes?: IFolderAttributes;
  active: number;
  creator: number;
  created: number;
  lastUpdate: number;
  disabledAt: number;
  disabledBy: number;
}

export interface IFolderAttributes {
  access_certifications?: number | number[];
  access_locations?: number | number[];
  access_permissions?: string[];
  access_roles?: number | number[];
  access_shifts?: string[];
  access_teams?: number | number[];
  document_review?: number;
  limit_access?: number;
  owner_only?: number;
  review_certifications?: number | number[];
  review_duration?: number;
  review_duration_unit?: string;
  reapproval_interval?: number;
  reapproval_interval_unit?: string;
  review_permissions?: string[];
  review_roles?: number | number[];
  review_teams?: number | number[];
  reviewer_count?: number;
  reviewers?: number | number[];
  settings_inherited?: number;
  selectedProperties?: number[];
  selectedContent?: ContentPickerSelection[];
  users?: number[];
}

export interface ITreeFolder extends IFolder {
  children?: IFolder[];
  childrenIds?: number[];
}

export interface FolderPickerConfig {
  entities: FolderPickerEntities;
  selectAllLabel?: string;
  selectedIds: FolderPickerSelection[];
  parentIds: FolderPickerSelection[];
  dataType: FolderDataType;
  getFolderItems?(): FolderPickerItem[];
  getItemId?(item: FolderPickerSelection | FolderPickerItem): number;
  getItemTitle?(item: FolderPickerItem): string;
  getFolderId(item: FolderPickerSelection | FolderPickerEntityFolder): string | number;
}

export interface FolderPickerEntities {
  folders?: IObjectStringKeyMap<FolderPickerEntityFolder>;
  folderItems?: FolderPickerItem[];
}

export interface FolderPickerEntityFolder extends IFolder {
  children?: FolderPickerEntityFolder[];
  folderItems?: FolderPickerItem[];
}

export interface FolderPickerSelection {
  folderID?: number;
  assetID?: number;
  itemID?: number;
}

export enum FolderDataType {
  ASSET = 'asset',
  PROPERTY = 'property',
  NOTIFICATION_RULE = 'notification_rule',
  RELAY = 'relay',
  CONTENT = 'content'
}

export interface AddFolderParameters {
  folderType: FolderDataType;
  folderID?: number;
  parentFolder?: number;
}

export type FolderPickerItem = Asset | IProperty | ObjectItem;
