import { IObjectStringKeyMap } from '@shared/models';
import { ObservationHistory } from '@services/observations/observation.interfaces';
import { ContentPickerSelection } from '@modules/management/pages/details/content/components';
import { FolderPickerSelection } from '@modules/management/modules/folders/model/folders.interfaces';
import { IPropertyEventActivity, IPropertyEventValue } from '@services/property/property-model.interfaces';
import {
  AssetStateHistoryEntity
} from '@modules/observation/pages/asset-status/pages/detail/model/asset-state.interfaces';

export interface Asset {
  active: number;
  assetID: number;
  assetType: number;
  assetUUID: string;
  beacons: string[];
  contentItems: string[];
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  folderID: number;
  identifier: string;
  images: string[];
  monitorHealth: number;
  health: number;
  stateEntered?: number;
  lastUpdate: number;
  location: number;
  name: string;
  nfcTags: string[];
  translations: string[];
  users: [string];
  parentContent?: ContentPickerSelection[];
  properties?: IPropertyMeta[];
  parentProperties?: IPropertyMeta[];
  selectedProperties?: IPropertyMeta[];
  observationEvents?: ObservationHistory[];
  propertyValues?: IObjectStringKeyMap<IPropertyValue>;
  propertyEvents?: IObjectStringKeyMap<IPropertyValue[]>;
  zone: number;
  currentZone: number;
  healthEvents?: IObjectStringKeyMap<HealthEvent[]>;
  lastEvent?: number;
}

export interface ReportAsset extends Asset {
  healthEntities?: AssetStateHistoryEntity[];
}

export interface ReportAsset extends Asset {
  healthEntities?: AssetStateHistoryEntity[];
}

export interface HealthEvent {
  health: number;
  metric: number;
  time: number;
}

export interface IPropertyMeta {
  propertyID: number;
  healthRelated: number;
}

export interface IPropertyValue {
  activity: PropertyActivity | IPropertyEventActivity;
  time: number;
  userID: number;
  value: string | IPropertyValueZone | IPropertyEventValue;
  health?: number;
  source?: string;
}

export interface IPropertyValueZone {
  rssi: number;
  zoneID: number;
}

export interface AssetFilterParameters {
  locations?: number[];
  zones?: (number | string)[];
  users?: number[];
  states?: number[];
  assetsFolders?: FolderPickerSelection[];
}

export enum AssetFilterMenuFormField {
  AssetFolderPicker = 'assetFolderPicker',
  AssetStates = 'assetStates'
}

export enum PropertyActivity {
  OutOfRange = 'outOfRange',
  InRange = 'inRange',
  Manual = 'manual',
  Calculated = 'calculated',
}
