import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { ShiftService } from '@services/shift/shift.service';
import { Events } from '@services/events/events.service';
import { UserdataService } from '@services/userdata/userdata.service';

import { TranslateService } from '@ngx-translate/core';
import { IObjectStringKeyMap } from '@shared/models';
import { Store } from '@ngrx/store';
import { IAppState } from '@store/IAppState';
import { UtilsService } from '@services/utils/utils.service';
import { updateLocations } from '@store/store.actions';
import {
  cloneDeep,
  each,
  find, flatten,
  get,
  has,
  intersection,
  isArray, isEmpty,
  isUndefined,
  keyBy,
  map,
  omitBy,
  reject,
  sortBy, toLower,
  every, includes, split
} from 'lodash';
import * as moment from 'moment-timezone';
import { TranslationItem, TranslationObject } from '@modules/shared/models/ITranslations';
import { HierarchyGroupingService } from '@services/hierarchyGrouping/hierarchy-grouping.service';
import { ContentPickerSelection } from '@modules/management/pages/details/content/components';

export interface IBeacon { // TODO [azyulikov] move into interfaces.ts
  beaconID: string;
  lastUpdate: string;
  lastUpdatedBy: number;
  locationID: number;
  name: string;
  nfcID: string;
  threshold: number;
  zoneID: number;
}

export interface IZone {
  name: string;
  isOpened?: boolean;
  zoneID: number;
  beacons?: IBeacon[];
  zones?: IZone[];
  disabledAt?: number;
  description: string;
  disabledBy: number;
  locationID: number;
  certificationAdds?: number[];
  certificationExceptions?: number[];
  gearAdds?: number[];
  gearExceptions?: number[];
  coretargets?: { name: string }[];
  parent?: IZone;
}

export interface ILocation {
  name: string;
  isOpened: boolean;
  zones?: IZone[];
  locationID: number;
  description?: string;
  address: string;
  certifications: number[];
  city: string;
  contact: string;
  country: string;
  disabledAt: number;
  disabledBy: number;
  gear: number[];
  lat: number;
  lon: number;
  timezone?: string;
  phone: string;
  state: string;
  zip?: string;
  preferences?: any;
  contentItems: number[];
  selectedContent?: ContentPickerSelection[];
  features?: any;
}

export interface ILocationsData {
  lastHash: string;
  lastRequest: number;
  data: ILocation[];
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  beaconsByLocation: any = {};
  locationsMap: IObjectStringKeyMap<ILocation> = {};
  locations: ILocationsData = {
    lastHash: null,
    lastRequest: null,
    data: []
  };

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private subscriber: SubscriberService,
    private accountsService: AccountsService,
    private shifts: ShiftService,
    private translate: TranslateService,
    private events: Events,
    private store: Store<{AppStateReducer: IAppState}>,
    private userData: UserdataService,
    private hierarchyGroupingService: HierarchyGroupingService
  ) { }

  public getLocations(updated: number = 0) {
    if (updated && updated < this.locations.lastRequest) {
      return Promise.resolve(this.locations.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getLocations',
          includeDisabled: 1,
          lastRequest: this.locations.lastRequest,
          lastHash: this.locations.lastHash,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateLocationCache(data);
            this.locations.lastHash = data.result.datahash;
          }
          resolve(this.locations.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.locations.lastHash = null;
    this.locations.lastRequest = null;
    this.locations.data = null;
    this.beaconsByLocation = {};
  }

  public updateLocationCache(data) {
    this.locations.lastRequest = data.result.timestamp;
    this.locations.data = data.result.locations;
    this.locationsMap = keyBy(this.locations.data, 'locationID');
    this.beaconsByLocation = {};
    this.events.publish('ccs:locationsUpdate');
    this.store.dispatch(updateLocations({locations: cloneDeep(this.locations) }));
  }

  public getLocation(locationID: number): ILocation {
    return this.locationsMap[locationID] || find(this.locations.data, {locationID} as any);
  }

  public locationDateTimeFormat(locationID: number, unixTime: number, none: string = 'None'): string {
    if (unixTime) {
      let t;
      let timezone;
      let showTZ = '';
      if (locationID) {
        const theLoc = this.getLocation(locationID);
        timezone = theLoc?.timezone;
      }
      if (timezone) {
        t = moment.tz(unixTime * 1000, timezone);
        showTZ=' z';
      } else {
        t = moment(unixTime * 1000);
      }

      if (this.userData.getUnits('time') === '24h') {
        return t.format(`MMM\xa0DD,\xa0YYYY\xa0HH:mm${showTZ}`);
      } else {
        return t.format(`MMM\xa0DD,\xa0YYYY\xa0h:mm\xa0A${showTZ}`);
      }
    } else {
      return none;
    }
  }

  public getAvailableLocations(includeDisabled: boolean = true, sorted: boolean = true): ILocation[] {
    return this.getUserLocations(this.userData.locations, includeDisabled, sorted);
  }



  public getUserLocations(locations, includeDisabled: boolean = true, sorted: boolean = true) {
    return UserService.getUserLocationsStatic(this.locations.data, locations, includeDisabled, sorted);
  }

  public hasFullLocationAccess() {
    const availableLocations: ILocation[] = this.getUserLocations(this.userData.locations, false);
    const allLocations = reject(this.locations.data, 'disabledBy');

    return availableLocations.length === allLocations.length;
  }

  public getActiveLocationsByIds(locationsIds): any[] {
    return reject(map(locationsIds, (locationId: number) => this.findLocation(locationId)), 'disabledAt');
  }

  public getActiveZonesByLocationsIds(locationsIds: number[]): any[] {
    const locations: any = this.getActiveLocationsByIds(locationsIds);
    return reject(flatten(map(locations, 'zones')), 'disabledAt');
  }

  public currentLocation() {
    return this.findLocation(this.subscriber.subInfo.locationID);
  }

  public findLocation(locID): any {
    return find(this.locations.data, <any>{locationID: +locID});
  }

  public handleUpdateLocation(fData) {
    fData.cmd = 'updateLocation';

    each(['gear', 'certifications', 'preferences', 'features', 'units', 'plugins'], (item) => {
      if (fData.hasOwnProperty(item) && (typeof fData[item] !== 'string' && typeof fData[item] !== 'number')) {
        fData[item] = JSON.stringify(fData[item]);
      }
    });

    return this._updateAndRefresh(fData, () => new Promise((resolve, reject) => {
      Promise.all([this.getLocations(), this.shifts.refresh()])
        .then((resAry) => {
          resolve(true);
        })
        .catch((resAry) => {
          resolve(true);
        });
    }));
  }

  public getShiftList(locations, includeDisabled: boolean = false, sort: boolean = false) {
    let r = this.shifts.filterShiftList([
      (shift) => {
        if (!includeDisabled && shift.disabledAt) {
          return true;
        }
        // if there are no locations in the list, then this user has access to all locations
        if (locations.length === 0) {
          return false;
        }
        const overlap = intersection(locations, [shift.locationID]);
        return !overlap.length;
      }
    ]);
    if (sort) {
      r = sortBy(r, [(item) => {
        const l: any = find(this.locations.data, ['locationID', item.locationID]);
        // TODO: how come location has length? needs to be fixed
        if (l && l.length) {
          return l[0].name;
        } else {
          return 'Unknown';
        }
      }, 'name']);
    }
    return r;
  }

  getLocationPreference(locationID: number, preference: string): any {
    let ret = null;
    const l = this.findLocation(locationID);
    if (l) {
      if (has(l, 'preferences')) {
        ret = get(l.preferences, preference);
      }
    }
    return ret;
  }

  public handleUpdateLocationPreference(locationID: number, preference: string, setting: any) {
    const l = this.findLocation(locationID);
    if (!l) {
      return Promise.reject('no such location');
    } else {
      const p = l.preferences ? cloneDeep(l.preferences) : {};
      p[preference] = setting;
      const fData = {
        preferences: JSON.stringify(p),
        locID: locationID
      };
      return this.handleUpdateLocation(fData);
    }
  }

  public handleAddZone(formData: any) {
    formData.cmd = 'addZone';
    formData.sendTime = Date.now();

    if (formData.gearAdds && Array.isArray(formData.gearAdds)) {
      formData.gearAdds = JSON.stringify(formData.gearAdds);
    }
    if (formData.gearExceptions && Array.isArray(formData.gearExceptions)) {
      formData.gearExceptions = JSON.stringify(formData.gearExceptions);
    }
    if (formData.certificationAdds && Array.isArray(formData.certificationAdds)) {
      formData.certificationAdds = JSON.stringify(formData.certificationAdds);
    }
    if (formData.certificationExceptions && Array.isArray(formData.certificationExceptions)) {
      formData.certificationExceptions = JSON.stringify(formData.certificationExceptions);
    }

    return this._updateAndRefresh(formData, () => this.getLocations());
  }

  public handleUpdateZone(fData) {
    fData.cmd = 'updateZone';

    if (fData.certificationAdds && Array.isArray(fData.certificationAdds)) {
      fData.certificationAdds = JSON.stringify(fData.certificationAdds);
    }
    if (fData.certificationExceptions && Array.isArray(fData.certificationExceptions)) {
      fData.certificationExceptions = JSON.stringify(fData.certificationExceptions);
    }
    if (fData.gearAdds && Array.isArray(fData.gearAdds)) {
      fData.gearAdds = JSON.stringify(fData.gearAdds);
    }
    if (fData.gearExceptions && Array.isArray(fData.gearExceptions)) {
      fData.gearExceptions = JSON.stringify(fData.gearExceptions);
    }
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleDeleteZone(fData) {
    fData.cmd = 'deleteZone';
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public getZoneById(zones: IZone[], id: number): IZone {
    let currentZone: IZone = find(zones, {zoneID: id});

    if (!currentZone) {
      each(zones, (zone: IZone) => {
        if (zone.zones && zone.zones.length) {
          currentZone = this.getZoneById(zone.zones, id);
          if (currentZone) {
            return false;
          }
        }
      });
    }

    return currentZone;
  }

  public getZonePathById(zones: IZone[], id: number, locID?: number): string {
    if (!zones && locID) {
      const locRef = this.getLocationByID(locID);
      if (!locRef) {
        return '';
      } else {
        zones = locRef.zones;
      }
    }

    if (id === 0) {
      return this.translate.instant('SHARED.Site-wide');
    }

    const currentZone: IZone = find(zones, {zoneID: id});
    let ret = '';

    if (currentZone) {
      ret = currentZone.name;
    } else {
      each(zones, (zone: IZone) => {
        if (zone.zones && zone.zones.length) {
          const childName = this.getZonePathById(zone.zones, id);
          if (childName !== '') {
            ret += `${zone.name} / ${childName}`;
            return false;
          }
        }
      });
    }

    return ret;

  }

  public handleUpdateBeacon(fData: any) {
    const adjacentBeacons: any[] = [];
    fData.adjacentBeacons = JSON.stringify(adjacentBeacons);

    if (fData.hasOwnProperty('usesThreshold') && fData.usesThreshold) {
      fData.threshold = +fData.threshold;
    } else {
      fData.threshold = 0;
    }
    fData.cmd = 'updateBeacon';

    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleUpdateCoreTarget(fData: any) {
    fData.cmd = 'updateTarget';
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleDeleteCoreTarget(fData: any) {
    fData.cmd = 'deleteTarget';
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleDeleteBeacon(fData) {
    fData.cmd = 'deleteBeacon';
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleAddLocation(fData): any {
    fData.cmd = 'addLocation';
    fData.sendTime = Date.now();
    if (fData.gear && Array.isArray(fData.gear)) {
      fData.gear = JSON.stringify(fData.gear);
    }

    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  public handleDeleteLocation(fData): any {
    fData.cmd = 'deleteLocation';
    return this._updateAndRefresh(fData, () => this.getLocations());
  }

  handleUpdateUser(fData: any, doRefresh: boolean = true) {
    if (fData.password === '') {
      delete fData.password;
    }

    $.each(['permissions', 'roles', 'groups', 'certifications', 'locations'], (idx, name) => {
      if (fData.hasOwnProperty(name)) {
        if (typeof fData[name] === 'string') {
          fData[name] = [fData[name]];
        }
      } else {
        fData[name] = [];
      }
    });

    if (fData.type === 'observation') {
      fData.firstname = fData.description;
      fData.lastname = '';
    }

    if (fData.hasOwnProperty('defaultLanguage') || fData.hasOwnProperty('disabledAutoLogout')) {
      const newPreferences = omitBy({
        defaultLanguage: fData.defaultLanguage,
        disabledAutoLogout: fData.disabledAutoLogout
      }, isUndefined);

      const currentPreferences = Object.assign({}, get(this.accountsService.getAccount(+fData.userID), 'preferences'), newPreferences);
      fData.preferences = JSON.stringify(currentPreferences);
    }
    fData.cmd = 'updateUser';
    fData.sendTime = Date.now();
    if (doRefresh) {
      return this._updateAndRefresh(this.accountsService.encode(fData), () => this.accountsService.refresh());
    } else {
      return this._updateOnly(this.accountsService.encode(fData));
    }
  }

  handleDeleteUser(fData: any) {
    fData.cmd = 'deleteUser';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(fData, () => this.accountsService.refresh());
  }

  /*
  public handleAddMessage(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(fData, () => this.getLocations());
    return this.commsService.sendMessage(fData);
  }

  public handleAddCategory(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'category';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteCategory(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }

  public handleUpdateMessage(fData, locationID?: any) {
    // when the locationID is set, just update the location collection
    // of available messages of this type to include/exclude this item
    // based upon the 'active' flag
    fData.cmd = 'updateMessageTemplate';
    return this._sendMessage(fData);
  }

  public handleUpdateCategory(fData) {
    fData.type = 'category';
    return this._sendMessage(fData);
  }

  public handleUpdateBehavior(fData) {
    fData.cmd = 'updateMessageTemplate';
    fData.type = 'behavior';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteBehavior(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }

  public handleAddBehavior (fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'behavior';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleAddMitigation(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'mitigation';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleUpdateMitigation(fData) {
    fData.cmd = 'updateMessageTemplate';
    fData.type = 'mitigation';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteMitigation(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }

  public handleUpdateQualityCat(fData) {
    fData.cmd = 'updateMessageTemplate';
    fData.type = 'quality';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteQualityCat(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }

  public handleAddQualityCat (fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'quality';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleAddCompliment(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'compliment';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleUpdateCompliment(fData) {
    fData.cmd = 'updateMessageTemplate';
    fData.type = 'compliment';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteCompliment(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }


  private _sendMessage(fData) {
    fData.sendTime = Date.now();

    // roll up any translations
    const l = this.subscriber.getLanguages(true);
    if (l && l.length) {
      const t = [];
      each(l, (lang: string) => {
        const n = `translations_${lang}`;
        if (fData.hasOwnProperty(n)) {
          t.push( { language: lang, value: fData[n]});
        }
      });
      if (t.length) {
        fData.translations = JSON.stringify(t);
      }
    }
    return this.commsService.sendMessage(fData);
  }


  public handleAddShift(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'shift';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleUpdateShift(fData) {
    fData.cmd = 'updateMessageTemplate';
    fData.type = 'shift';
    fData.sendTime = Date.now();
    return this._sendMessage(fData);
  }

  public handleDeleteShift(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this.commsService.sendMessage(fData);
  }
  */

  handleAddUser(fData) {
    $.each(['permissions', 'roles', 'groups', 'certifications', 'locations'], (idx, name) => {
      if (fData.hasOwnProperty(name)) {
        if (typeof fData[name] === 'string') {
          fData[name] = [fData[name]];
        }
      } else {
        fData[name] = [];
      }
    });
    // if there is a shared permission, set the account type
    if (fData.type === 'observation') {
      fData.firstname = fData.description;
      fData.lastname = '';
    }
    if (fData.hasOwnProperty('defaultLanguage')) {
      const preferences = Object.assign({}, get(this.accountsService.getAccount(+fData.userID), 'preferences'), {
        defaultLanguage: fData.defaultLanguage
      });
      fData.preferences = JSON.stringify(preferences);
    }
    fData.primaryGroup = fData.primaryGroup || 0;
    fData.supervisorID = fData.supervisorID || 0;
    fData.cmd = 'addUser';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(this.accountsService.encode(fData), () => this.accountsService.refresh());
  }

  /**
   * accountAvatar - return the value for the avatar of a user
   *
   * @param userID - the ID of the user
   * @param [size] - an optional size for the image in pixels
   * @param [pictureOnly] - an optional flag that means only return a value if there is an actual picture
   *
   * @returns A string to use in the src attribute of an img element.
   *
   */
  public accountAvatar(userID, size, pictureOnly, useThumbnail) {
    let ret = null;

    if (size === null) {
      size = 64;
    }
    const uRef = this.accountsService.getAccount(userID);

    if (uRef) {
      // this is a known user... does it have a reference
      if (uRef.avatarID && uRef.avatarPath !== null) {
        // use the one from the backend if there is one
        ret = this.commsService.objectURI(uRef.avatarID, useThumbnail);
      } else if (!pictureOnly) {

        ret = 'assets/images/user_icon.png';
        let l = '';
        if (uRef) {
          if (uRef.firstname !== '') {
            l = UtilsService.charAtIdx(uRef.firstname, 0);
          } else if (uRef.lastname !== '') {
            l = UtilsService.charAtIdx(uRef.lastname, 0);
          }
        }
        if (l !== '' && l.match(/[a-zA-Z]/)) {
          ret = 'assets/images/avatars/' + l.toUpperCase() + '.svg';
        }
        // we are not native
        // if (environment.baseHref === '/') {
        //   ret = environment.baseHref + ret;
        // } else {
        //   ret = environment.baseHref + '/' + ret;
        // }
      }
    }
    return ret;
  }

//
  public findAnyZone(loc, zoneID, recursed?, active: boolean = false) {
    if (recursed === undefined) {
      recursed = false;
    }
    let ret = null;
    zoneID = Number(zoneID);
    if (!loc || zoneID === undefined || zoneID === null) {
      return null;
    }
    // okay - we have parameters
    if (zoneID === '0' || zoneID === 0) {
      // we are looking for no zone - take the location
      ret = {
        locationID: loc.locationID,
        zoneID,
        name: this.translate.instant('SHARED.Site-wide')
      };
    } else {
      if (!loc.zones || (typeof loc.zones === 'object' && !Array.isArray(loc.zones))) {
        // there's no list of zones for this loc
        return null;
      }
      $.each(loc.zones, (i, zoneRef) => {
        if (zoneRef.zoneID === zoneID && (!active || active && zoneRef.disabledAt === 0)) {
          ret = zoneRef;
          return false;
        } else {
          ret = this.findAnyZone(zoneRef, zoneID, true);
          if (ret) {
            return false;
          }
        }
      });

      if (ret) {
        ret = {...ret, name: this.translateItem(ret, 'name')};
      }

      if (ret === null && !recursed) {
        // we had a zone and couldnt find it.  use site wide
        ret = {
          locationID: loc.locationID,
          zoneID,
          name: this.translate.instant('SHARED.Site-wide')
        };
      }
    }
    return ret;
  }

  public findAnyZoneNoLoc(zoneID, active: boolean = false) {
    let ret = null;
    let lid = null;
    zoneID = +zoneID;
    $.each(this.locations.data, (i: any, ref) => {
      lid = i.locationID;
      const z = this.findAnyZone(ref, zoneID, true, active);
      if (z) {
        ret = z;
        return false;
      }
    });
    if (!ret || zoneID === 0) {
      // the zone didn't exist in any location?
      ret = {
        locationID: lid,
        zoneID: 0,
        name: this.translate.instant('SHARED.Site-wide')
      };
    }
    return ret;
  }

  /**
   * getFullname - return the fullname for a user
   *
   * @param userID - the ID for the user
   *
   * @returns the fullname of the user or null if the user does not exist.
   */
  getFullname(userID) {
    if (userID === 0) {
      return 'unassigned';
    }
    const rec = this.accountsService.getAccount(userID);
    let ret = null;
    if (rec) {
      ret = rec.firstname + ' ' + rec.lastname;
    } else {
      return 'unknown';
    }
    return ret;
  }

  /**
   * getCompactName - return a compact version of the name for an account
   *
   * @param userID - the userID associated with the account
   *
   * @returns The first name and last initial if there is one.
   */
  getCompactName(userID) {
    if (userID === 0) {
      return 'unassigned';
    }

    if (userID === -1) {
      return this.translate.instant('SHARED.Anonymous');
    }

    const rec = this.accountsService.getAccount(userID);
    let ret = null;
    if (rec) {
      ret = rec.firstname;
      if (rec.lastname && rec.lastname.length > 0) {
        ret += ' ' + UtilsService.charAtIdx(rec.lastname , 0) + '.';
      }
    } else {
      return 'unknown';
    }
    return ret;
  }

  /**
   *  buildLocationMenu - select2 component for filters and forms
   *
   * @return array of object with locaiton name and location ID
   */
  buildLocationMenu(locations?: any, includeDisabled?: boolean) {
    const tempLocArray = [];
    const thedata = locations !== undefined ? this.getUserLocations(locations, includeDisabled) : this.locations.data;
    each(thedata, data => {
      if (!includeDisabled && data.disabledAt) {
        // do nothing
      } else {
        tempLocArray.push({
          id: data.locationID,
          text: data.disabledAt ? data.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.name
        });
      }
    });
    return {
      name: 'Location',
      dropDownOptions: tempLocArray,
    };
  }

  //
  /**
   * buildZoneMenu - create a selection menu of zones in a location
   *
   * @param locationID - the ID of the location to get zones for.  If locationID is 0, do all locations.
   *  If locationID is a reference to an array, process all of the locations in that list.
   * // tslint:disable-next-line:no-redundant-jsdoc
   * @param [current] - the default selection; defaults to none.  If current is a reference to an
   * array, there are multiple currently selected items
   * @param [includeDisabled] - include disabled zones.  Default is false.
   *
   * @param [order] - orders zone options alphabetically
   *
   * @returns a Promise that resolves with a String that represents a
   *                    collection of option elements for use in a select menu
   */
  buildZoneMenu(locationID: Number | Array<number>, includeDisabled?: boolean, order?: boolean) {
    if (includeDisabled === undefined) {
      includeDisabled = false;
    }
    let locationList = [];

    if (locationID === undefined || locationID === null || typeof locationID === 'number' && !locationID || (isArray(locationID) && isEmpty(locationID))) {
      const locData = this.getUserLocations([], includeDisabled);
      $.each(locData, (idx, ref) => {
        locationList.push(ref.locationID);
      });
    } else if (typeof locationID === 'object' && Array.isArray(locationID)) {
      locationList = locationID;
    } else if (typeof locationID === 'number') {
      locationList.push(locationID);
    } else {
      this.logger.log('Invalid locationID parameter');
    }


    const tempZoneArray = [];
    // if there is only one item in the list of locations, don't include the
    // location name as an optgroup
    $.each(locationList, (idx, lid) => {
      const lref = this.findLocation(lid);
      if (!lref) {
        // can't find a location - skip it
        this.logger.log('Can\'t find location ' + lid);
        return true;
      }

      const optGroup: any = {
        text: lref.name,
      };

      let childrenArray: any = [];
      childrenArray.push({
        id: lid + ':' + 0,
        text: this.translate.instant('SHARED.Site-wide'),
        locationID: lid
      });
      if (lref.zones && lref.zones.length) {
        $.each(lref.zones, (index, ref) => {
          if (!includeDisabled && ref.disabledAt) {
            return true;
          }
          const id = ref.zoneID;
          const zoneName = this.translateItem(ref, 'name');
          childrenArray.push({
            id,
            text: ref.disabledAt ? zoneName + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : zoneName
          });

          // let's iterate through the list of sub-zones if any
          if (ref.zones && ref.zones.length) {
            $.each(ref.zones, (idxn, refSub) => {
              if (!includeDisabled && refSub.disabledAt) {
                return true;
              }

              childrenArray.push({
                id: refSub.zoneID,
                text: this.translateItem(refSub, 'name')
              });
            });
          }
        });

      }
      if (order) {
        childrenArray = sortBy(childrenArray, ['text']);
      }
      optGroup.children = childrenArray;
      tempZoneArray.push(optGroup);
    });
    return {
      name: 'Zone',
      dropDownOptions: tempZoneArray,
    };
  }

  public translateItem(item: { [key: string]: any }, name: string): string {
    const currentLanguage: string = this.userData.getLanguage();
    let translatedValue = get(item, name);

    if (currentLanguage) {
      const translations = get(item, 'translations');
      const translatedObject: { [key: string]: any } = find(get(translations, name) || translations, {language: currentLanguage});

      if (translatedObject) {
        translatedValue = translatedObject.value;
      }
    }

    return translatedValue;
  }

  public getLocationByID(id: number) {
    return find(this.locations.data, { 'locationID': id } );
  }

  public getLocationByName(name: string) {
    return find(this.locations.data, (loc) => toLower(loc.name) === toLower(name));
  }

  private _updateAndRefresh(fData, refreshHandler): Promise<any> {
    const cmdOpts = cloneDeep(fData);

    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            refreshHandler()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }

  private _updateOnly(fData): Promise<any> {
    const cmdOpts = cloneDeep(fData);

    return this.commsService.sendMessage(cmdOpts, false, false);
  }

  /**
   *
   * @param filters array of functions that are called with the user recored as a parameter.  The function returns true if the item should be filtered out of the user list.
   */
  static filterLocationList(filters: any, data) {
    const r = [];
    each(data, (ref) => {
      let matched = true;
      if (Array.isArray(filters)) {
        each(filters, (test) => {
          if (test(ref)) {
            matched = false;
          }
        });
      }
      if (matched) {
        r.push(ref);
      }
    });
    return r;
  }

  static getUserLocationsStatic(data, locations, includeDisabled: boolean = true, sorted: boolean = true) {
    const l = UserService.filterLocationList([
      (location) => {
        if (!includeDisabled && location.disabledAt) {
          return true;
        }
        // if there are no locations in the list, then this user has access to all locations
        if (locations.length === 0) {
          return false;
        }
        const overlap = intersection(locations, [location.locationID]);
        return !overlap.length;
      }
    ], data);

    if (!sorted) {
      return l;
    } else {
      return sortBy(l, ['name']);
    }
  }

  public getTranslatedValue(translationItem: TranslationItem, targetName: string): string {
    const currentLanguage: string = this.userData.getLanguage();
    let result: string;

    if (currentLanguage) {
      const translatedObject = find<TranslationObject>(translationItem?.translations, { language: currentLanguage });
      result = translatedObject?.value;
    }

    return result || translationItem?.[targetName] || '';
  }

  public hasLocationAccessTo(ids: (string | number)[]): boolean {
    const locationIds = this.userData.locations || [];

    if (locationIds.length === 0) {
      return true;
    } else {
      return every(ids, (id) => {
        if (includes(id as string, 'node')) {
          const [, folderId] = split(id as string, 'node:')
          return !!this.hierarchyGroupingService.getFolderByID(folderId);
        } else {
          return includes(locationIds, id);
        }
      });
    }
  }
}
